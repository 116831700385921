import React from "react"
import classnames from "classnames"

import styles from "./ctas.module.scss"

const Ctas = ({ items }) => {
  if (items) {
    return items.map(({ link, text }, index) => (
      <a
        key={index}
        href={link}
        title={text}
        className={classnames(
          "button",
          "block",
          index !== 0 ? "buttonHollow" : "",
          styles.button
        )}
        rel="noopener noreferrer nofollow"
      >
        {text ? text : `View link ${index + 1}`}
      </a>
    ))
  }

  return null
}

export default Ctas

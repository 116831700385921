import React from "react"
import styles from "./updates.module.scss"

const Updates = ({ updates }) => {
  if (updates.length > 0) {
    return (
      <div className={styles.Updates}>
        <h2 className={styles.title}>Updates</h2>
        <ul className={styles.list}>
          {updates.map((update, index) => (
            <li key={index} className={styles.item}>
              <time className={styles.time} dateTime={update.date}>
                {update.date}
              </time>{" "}
              <div className={styles.content}>
                <p dangerouslySetInnerHTML={{ __html: update.content }} />
              </div>
            </li>
          ))}
        </ul>
      </div>
    )
  }

  return null
}

export default Updates

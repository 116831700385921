import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import styles from "./profile.module.scss"

import SvgTwitter from "../icons/twitter.inline.svg"
import SvgGithub from "../icons/github.inline.svg"
import SvgLinkedIn from "../icons/linkedin.inline.svg"
import SvgEmail from "../icons/email.inline.svg"
import SvgChevronDown from "../icons/chevron-down.inline.svg"

const Profile = () => {
  const query = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            profile {
              bio
              bio3rd
              availability
            }
          }
        }

        avatar: file(relativePath: { eq: "avatar-small@2x.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 75) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return (
    <div className={styles.Profile}>
      <div className={styles.bio}>
        <Img
          fadeIn={false}
          fluid={query.avatar.childImageSharp.fluid}
          alt="Photo of Ian Lunn"
          className={styles.avatar}
        />
        <p
          style={{ marginBottom: 0 }}
          dangerouslySetInnerHTML={{
            __html: query.site.siteMetadata.profile.bio3rd,
          }}
        />
        <div className={styles.chevronDown}>
          <SvgChevronDown width="1em" height="1em" />
        </div>
      </div>
      <ul className={styles.links}>
        <li className={styles.linkItem}>
          <a
            className={styles.link}
            href="https://twitter.com/IanLunn/"
            title="Ian Lunn on Twitter"
          >
            <span className={styles.icon}>
              <SvgTwitter
                width="1.25em"
                height="1.25em"
                style={{ display: "block" }}
              />
            </span>
          </a>
        </li>
        <li className={styles.linkItem}>
          <a
            className={styles.link}
            href="https://github.com/IanLunn/"
            title="Ian Lunn on GitHub"
          >
            <span className={styles.icon}>
              <SvgGithub
                width="1.25em"
                height="1.25em"
                style={{ display: "block" }}
              />
            </span>
          </a>
        </li>
        <li className={styles.linkItem}>
          <a
            className={styles.link}
            href="https://uk.linkedin.com/in/ianlunn/"
            title="Ian Lunn on LinkedIn"
          >
            <span className={styles.icon}>
              <SvgLinkedIn
                width="1.25em"
                height="1.25em"
                style={{ display: "block" }}
              />
            </span>
          </a>
        </li>
        <li className={styles.linkItem}>
          <Link to="/contact" className={styles.link} title="Contact Ian Lunn">
            <span className={styles.icon}>
              <SvgEmail
                width="1.25em"
                height="1.25em"
                style={{ display: "block" }}
              />
            </span>
          </Link>
        </li>
      </ul>
    </div>
  )
}

export default Profile

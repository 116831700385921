import React from "react"

import styles from "./bmcButton.module.scss"

const BMCButton = () => (
  <a
    className={styles.bmcButton}
    target="_blank"
    href="https://www.buymeacoffee.com/ianlunn"
    rel="noopener noreferrer"
  >
    <img
      className={styles.image}
      src="https://cdn.buymeacoffee.com/buttons/bmc-new-btn-logo.svg"
      alt="Useful? Buy me a coffee"
    />
    <span className={styles.label}>Useful? Buy me a coffee</span>
  </a>
)

export default BMCButton
